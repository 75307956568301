// hooks.js
import { useState, useEffect } from "react";
import messageService from "./services/messageService";
function generateUUIDv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0]) &
      (15 >> (c / 4))
    ).toString(16)
  );
}

export const useMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      // Use chatId in the fetchMessages call
      const response = await messageService.fetchMessages();
      setMessages(response.messages);
    };

    fetchMessages();
  }, []); // Add chatId as a dependency to useEffect

  const sendMessage = async (content) => {
    let userMessage = {
      id: generateUUIDv4(),
      content: content,
      role: "user",
    };
  
    setMessages(prevMessages => [...prevMessages, userMessage]);
  
    // Send the message and handle the response
    var lastUUID = null;
    messageService.sendMessage(content, (receivedMessage) => {
      if (lastUUID == null || lastUUID !== receivedMessage.id) {
        setMessages(prevMessages => [...prevMessages, receivedMessage]);
      }else{
         // Update the current assistant message
        setMessages(prevMessages => prevMessages.map(msg => {
          if (msg.id === receivedMessage.id) {
            return receivedMessage;
          }
          return msg;
        }));
      }
      lastUUID = receivedMessage.id;
      
    });
  };
  
  

  return { messages, sendMessage };
};

// Custom hook to handle typing indicator
export const useTypingIndicator = () => {
  const [isTyping, setIsTyping] = useState(false);

  // Add logic here to set isTyping to true when the other person is typing
  // and to false when they stop typing

  return { isTyping, setIsTyping };
};
