import React from 'react';
const LinkCode = () => {

    return (
        <div className="chat-interface flex flex-col items-center w-full min-h-screen">
            <div className="chat-content w-full md:w-3/4 mx-auto flex flex-col">
                {/* Top header with behumane.ai and Back to Chat links */}
                <div className="flex justify-between items-center w-full">
                    <a href="https://behumane.ai" className="text-sm text-white hover:text-gray-300 p-4">behumane.ai</a>
                    <a href="/" className="text-sm text-white hover:text-gray-300 p-4">Back to Chat</a>
                </div>
                {/* Heading and Sub-heading text */}
                <div className="mb-6 text-center">
                    <h1 className="text-4xl font-bold text-white mb-2">Sign In</h1>
                    <p className="text-sm lg:taext-lg text-white">
                        Be Humane is your safe space to share your thoughts, feelings, and questions.
                    </p>
                </div>
                {/* Centered box for Sign-in message */}
                <div class="flex justify-center min-h-screen">
                <div style={{ marginTop: '10%' }} className="w-full md:w-1/2 lg:w-1/3 p-8 rounded-lg shadow-md">
                        <div class="login-container">
                            <main class="login-form">
                                <form>
                                    <label className="text-white">
                                        Welcome to BeHuman(e) 👋
                                        Rhea is looking forward to chatting with you.
                                        Almost there. Please check your email for the access link.
                                    </label>
                                </form>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sticky footer */}
            <div className="sticky-footer bg-gradient-to-b from-[#25164a] to-[#674088] p-2 w-full">
                <div className="flex justify-between items-center text-white text-sm">
                    <a href="#help" className="hover:text-gray-300">Need help?</a>
                </div>
                <footer className="w-full text-center mt-auto">
                    <p className="text-white text-sm opacity-75">
                        © {new Date().getFullYear()} Be Humane, co.
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default LinkCode;
